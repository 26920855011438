import { Box, styled } from "@mui/material"
import { colors } from "../../../../../../utils"

export const EntriesWrapper = styled(Box)`
  display: flex;
  width: 100%;
  overflow: auto;
  gap: 12px;
  padding-bottom: 12px;
  padding-top: 8px;
`

export const EntryCard = styled(Box)`
  min-width: 400px;
  width: 400px;
  height: 360px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  padding: 16px 12px;
  background-color: ${colors.gray8};
  border: 1px solid ${colors.gray5};
  border-radius: 12px;
  overflow: auto;
  overscroll-behavior: contain;
`

export const AddEntryButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 8px ${colors.gray2};
  }
`
