import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import WarningIcon from "@mui/icons-material/Warning"
import { colors } from "../../utils"
import { LoadingButton } from "@mui/lab"

interface IProps {
  isVisible: boolean
  message: string
  confirmLabel?: string
  icon?: JSX.Element
  loading?: boolean
  onCancel?: () => void
  onConfirm?: () => void
}

export const AlertDialog = (props: IProps) => {
  const {
    isVisible,
    message,
    confirmLabel,
    icon,
    loading,
    onCancel,
    onConfirm,
  } = props
  const { t } = useTranslation()

  return (
    <Dialog
      open={isVisible}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box bgcolor={colors.white2}>
        <DialogContent>
          <Box display="flex" alignItems="center">
            {icon ?? <WarningIcon fontSize="large" color="primary" />}
            <Typography variant="large" marginLeft="16px">
              {message}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            {t("cancel")}
          </Button>
          <LoadingButton onClick={onConfirm} loading={loading} autoFocus>
            {confirmLabel ?? t("confirm")}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
