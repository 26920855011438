import { axios } from "../lib"

export const getOrganizationsAPI = (
  params?: IGetOrganizationsParams,
): Promise<IOrganization[]> => axios.get("Group/groups", { params })

export const switchActiveGroupAPI = (id: string): Promise<IOrganization[]> =>
  axios.put(`Group/switch-active-group?groupId=${id}`)

export const createGroupAPI = (body: ICreateGroupBody): Promise<IGroup> =>
  axios.post("Group", body)

export const updateGroupAPI = (
  id: string,
  body: ICreateGroupBody,
): Promise<IOrganization[]> => axios.put(`Group/${id}`, body)

export const deleteGroupAPI = (id: string): Promise<void> =>
  axios.delete(`Group/group?groupId=${id}`)

export const getOrganizationsManagementAPI = (): Promise<
  IOrganizationManagement[]
> => axios.get("Organization/organizations")

export const createOrganizationAPI = (
  body: IOrganizationManagementBody,
): Promise<IGroup> => axios.post("Organization", body)

export const updateOrganizationAPI = (
  id: string,
  body: IOrganizationManagementBody,
): Promise<IOrganization[]> => axios.put(`Organization/${id}`, body)

export const deleteOrganizationAPI = (id: string): Promise<void> =>
  axios.delete(`Organization/${id}`)
