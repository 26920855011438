import { Box, Button as ButtonMui, styled } from "@mui/material"
import { colors } from "../../../../../../utils"

interface IButtonProps {
  isSelected: boolean
}

export const Header = styled(Box)`
  display: flex;
  margin: 10px;
  justify-content: center;
`

export const Wrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`

export const CardContainer = styled(Box)`
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: center;
`

export const Button = styled(ButtonMui)<IButtonProps>`
  padding: 0;
  border-radius: 8px;
  text-transform: none;
  background-color: ${(props: IButtonProps) =>
    props.isSelected ? colors.green2 : "#f9f9f9"};
  transition:
    transform 0.2s,
    background-color 0.2s;

  &:hover {
    transform: scale(1.009);
    background-color: ${(props: IButtonProps) =>
      props.isSelected ? colors.green : "#f0f0f0"};
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 100px;
  border: 1px solid #ddd;
`
