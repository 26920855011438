import { useCallback, useEffect, useState } from "react"
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import { type FieldValues, useForm, Controller } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { ReactComponent as SuccessCheckIcon } from "../../assets/icons/success-check.svg"
import { colors } from "../../utils"
import { ClickableLabel, CodeInput } from "../../components"
import { resendOtpAPI, verifyEmailAPI } from "../../services"
import { LoadingButton } from "@mui/lab"
import { useAppContext, useToast } from "../../contexts"

export const VerificationPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isOtpFormVisible, setOtpFormVisible] = useState(false)
  const [searchParams] = useSearchParams()
  const toast = useToast()
  const { verifyOtpMutation } = useAppContext()

  const {
    mutate: verifyOtp,
    isSuccess: isOtpVerified,
    isPending: isOtpVerifying,
  } = verifyOtpMutation

  const {
    mutate: verifyEmail,
    isSuccess: isEmailVerified,
    isPending: isEmailVerifying,
    isError: isEmailVerificationFailed,
  } = useMutation({
    mutationFn: (body: IVerifyEmailBody) => verifyEmailAPI(body),
  })

  const { mutate: resendOtp, isPending: isOtpResending } = useMutation({
    mutationFn: (body: string) => resendOtpAPI(body),
    onSuccess: () => {
      toast.show(t("otpResent"), "success")
    },
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      code: "",
    },
  })

  const userId = searchParams.get("userId")?.replace(/ /g, "+")!
  const token = searchParams.get("token")?.replace(/ /g, "+")!

  useEffect(() => {
    verifyEmail({ token, userId })
  }, [])

  const handleFormSubmit = useCallback(
    (data: FieldValues) => {
      verifyOtp({ userId, otp: data.code })
    },
    [userId],
  )

  const handleResendCode = () => {
    resendOtp(userId)
  }

  const onContinueToOTP = useCallback(() => setOtpFormVisible(true), [])

  const onBackToLoginClick = useCallback(() => navigate("../login"), [])

  const onBackToRegisterClick = useCallback(() => navigate("../register"), [])

  if (isOtpVerified) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <SuccessCheckIcon />
        <Box marginBottom="16px" />
        <Typography variant="h3" textAlign="center">
          {t("thankYou")}
        </Typography>
        <Box marginBottom="12px" />
        <Typography variant="large" color={colors.black2} textAlign="center">
          {t("signUpSuccessDescription")}
        </Typography>
        <Typography marginTop="16px" color={colors.black2}>
          <Trans
            i18nKey="backToLogin"
            components={[
              <ClickableLabel
                key="1"
                onClick={onBackToLoginClick}
                variant="regularBold"
              />,
            ]}
          />
        </Typography>
      </Box>
    )
  }

  if (isOtpResending) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography marginBottom="24px" variant="h5" color={colors.primary}>
          {t("resendingOtpCode")}
        </Typography>
        <CircularProgress />
      </Box>
    )
  }

  if (isOtpFormVisible) {
    return (
      <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Typography variant="h3">{t("verification")}</Typography>
        <Typography variant="large" color={colors.black2}>
          {t("enterVerificationCode")}
        </Typography>
        <Box marginBottom="24px" />
        <Typography variant="large" color={colors.black2}>
          {t("enterYourCode")}
        </Typography>
        <Box marginBottom="24px" />
        <Controller
          control={control}
          name="code"
          rules={{
            minLength: {
              value: 6,
              message: t("codeLength"),
            },
            required: {
              value: true,
              message: t("required"),
            },
          }}
          render={({ field: { value, onChange } }) => (
            <CodeInput
              fieldsNumber={6}
              value={value}
              onChange={onChange}
              error={!!errors?.code}
              helperText={errors?.code?.message}
            />
          )}
        />
        <Box marginTop="16px" />
        <ClickableLabel onClick={handleResendCode} variant="regularBold">
          {t("resendCode")}
        </ClickableLabel>
        <Box marginTop="32px" marginBottom="16px">
          <LoadingButton fullWidth type="submit" loading={isOtpVerifying}>
            {t("submit")}
          </LoadingButton>
        </Box>
      </Box>
    )
  }

  if (isEmailVerifying) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography marginBottom="24px" variant="h5" color={colors.primary}>
          {t("verifyingEmail")}
        </Typography>
        <CircularProgress />
      </Box>
    )
  }

  if (isEmailVerificationFailed) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="large">{t("emailVerificationFailed")}</Typography>
        <Box marginBottom="12px" />
        <Typography marginTop="16px" color={colors.black2}>
          <Trans
            i18nKey="backToLoginOrRegister"
            components={[
              <ClickableLabel
                key="1"
                onClick={onBackToRegisterClick}
                variant="regularBold"
              />,
              <ClickableLabel
                key="2"
                onClick={onBackToLoginClick}
                variant="regularBold"
              />,
            ]}
          />
        </Typography>
      </Box>
    )
  }

  if (isEmailVerified) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <SuccessCheckIcon />
        <Box marginBottom="16px" />
        <Typography variant="h3" color={colors.black2} textAlign="center">
          {t("thankYou")}
        </Typography>
        <Box marginBottom="12px" />
        <Typography variant="large" color={colors.gray3} textAlign="center">
          {t("emailVerificationSuccessMessage")}
        </Typography>
        <Box marginBottom="24px" />
        <Button fullWidth onClick={onContinueToOTP}>
          {t("continue")}
        </Button>
      </Box>
    )
  }

  return null
}
