import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { getOrganizationsAPI, switchActiveGroupAPI } from "../../services"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "../../contexts"
import { GroupLineItem } from "../GroupLineItem"

interface IProps {
  open: boolean
  closeDialog: () => void
}

export const GroupSwitchDialog = (props: IProps) => {
  const { t } = useTranslation()
  const { open, closeDialog } = props
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>()
  const { data: organizations } = useQuery({
    queryKey: ["organizations"],
    queryFn: () => getOrganizationsAPI(),
  })
  const navigate = useNavigate()
  const { state: appState } = useAppContext()

  const { mutate, isPending: switchingPending } = useMutation({
    mutationFn: (id: string) => switchActiveGroupAPI(id),
    onSuccess: () => {
      sessionStorage.setItem("groupId", selectedGroup!)
      closeDialog()
      navigate("/")
      window.location.reload()
    },
  })

  const activeOrganization = useMemo(() => {
    return organizations?.find(
      (organization) =>
        organization?.groups?.some((group) => group.id === appState?.groupId),
    )
  }, [organizations])

  const activeGroup = useMemo(() => {
    return activeOrganization?.groups?.find(
      (group) => group.id === appState?.groupId,
    )
  }, [activeOrganization])

  const isSelected = useCallback(
    (id: string) => id === selectedGroup,
    [selectedGroup],
  )

  const handleGroupChange = useCallback(
    (id: string) => {
      if (id !== selectedGroup) {
        setSelectedGroup(id)
      }
    },
    [selectedGroup],
  )

  const handleSwitchGroup = useCallback(() => {
    if (selectedGroup && selectedGroup !== activeGroup?.id) {
      mutate(selectedGroup)
    }
  }, [selectedGroup, activeGroup])

  useEffect(() => {
    setSelectedGroup(activeGroup?.id)
  }, [activeGroup, open])

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t("groupSelection")}</DialogTitle>
      <DialogContent>
        {organizations
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((organization) => (
            <Box
              key={organization.id}
              gap="8px"
              display="flex"
              flexDirection="column"
            >
              <Typography textAlign="start" variant="regularBold">
                {organization.name}
              </Typography>
              {organization.groups
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((group) => (
                  <GroupLineItem
                    key={group.id}
                    group={group}
                    isSelected={isSelected(group.id)}
                    onChangeGroup={handleGroupChange}
                  />
                ))}
              <Box marginTop="2px" />
            </Box>
          ))}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={closeDialog}>
          {t("cancel")}
        </Button>
        <LoadingButton
          loading={switchingPending}
          disabled={selectedGroup === activeGroup?.id}
          onClick={handleSwitchGroup}
          className="button-min-width"
        >
          {t("switchGroup")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
