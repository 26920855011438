import {
  Box,
  Checkbox,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material"
import {
  useForm,
  Controller,
  type Control,
  type FieldValues,
} from "react-hook-form"
import { LoadingButton } from "@mui/lab"
import { useMutation } from "@tanstack/react-query"
import { resendEmailAPI, signupOrganizationAPI } from "../../services"
import { useToast } from "../../contexts"
import { useCallback } from "react"
import { Trans, useTranslation } from "react-i18next"
import {
  colors,
  EMAIL_REGEX_VALIDATION,
  PASSWORD_REGEX_VALIDATION,
} from "../../utils"
import { ReactComponent as CrashMateLogo } from "../../assets/icons/crashmate-logo.svg"
import { ClickableLabel, PhoneNumberInput } from "../../components"
import { isValidPhoneNumber } from "react-phone-number-input"
import { PlaceController } from "../ClaimPage/components/FormField/components/PlaceController"
import { useNavigate } from "react-router-dom"
import { ReactComponent as EmailVerifiedIcon } from "../../assets/icons/email-verified.svg"

export const RegisterOrganizationPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toast = useToast()

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: "",
      houseNumber: "",
      address: {
        postCodePlace: null,
        street: "",
      },
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      accountType: "",
      password: "",
      confirmPassword: "",
      termsAccepted: false,
      privacyAccepted: false,
      dataProcessingAccepted: false,
    },
  })

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: async (body: ISignupOrganizationRequestBody) =>
      await signupOrganizationAPI(body),
  })

  const { mutate: mutateResendEmail, isPending: pendingResend } = useMutation({
    mutationFn: async (body: string) => await resendEmailAPI(body),
    onSuccess: () => {
      toast.show(t("emailSent"), "success")
    },
  })

  const passwordValue = watch("password")

  const validatePhoneNumber = useCallback((value: string) => {
    if (!isValidPhoneNumber(value)) {
      return t("invalidPhoneNumberErrorMessage")
    } else {
      return true
    }
  }, [])

  const validateConfirmPassword = useCallback(
    (value: string) => {
      if (value === passwordValue) {
        return true
      } else {
        return t("passwordsDoNotMatch")
      }
    },
    [passwordValue],
  )

  const onSubmit = useCallback((data: FieldValues) => {
    const {
      termsAccepted: _termsAccepted,
      privacyAccepted: _privacyAccepted,
      dataProcessingAccepted: _dataProcessingAccepted,
      ...filteredData
    } = data

    mutate(filteredData as ISignupOrganizationRequestBody)
  }, [])

  const onResendClick = useCallback(() => {
    mutateResendEmail(watch("email"))
  }, [])

  const onBackToLoginClick = useCallback(() => navigate("../login"), [])

  if (pendingResend) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography marginBottom="24px" variant="h5" color={colors.primary}>
          {t("resendingEmail")}
        </Typography>
        <CircularProgress />
      </Box>
    )
  }

  if (isSuccess) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <EmailVerifiedIcon />
        <Box marginBottom="16px" />
        <Typography marginY="8px" variant="h3" textAlign="center">
          {t("verifyYourAddress")}
        </Typography>
        <Typography variant="large" color={colors.black2} textAlign="center">
          <Trans
            i18nKey="accountActivationDescription"
            components={[
              <ClickableLabel
                key="1"
                variant="largeBold"
                disabled={isPending}
                onClick={onResendClick}
              />,
            ]}
          />
        </Typography>
        <Typography marginTop="16px" color={colors.black2}>
          <Trans
            i18nKey="backToLogin"
            components={[
              <ClickableLabel
                key="1"
                variant="regularBold"
                onClick={onBackToLoginClick}
              />,
            ]}
          />
        </Typography>
      </Box>
    )
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <CrashMateLogo />
      <Typography marginY="8px" variant="h3">
        {t("getStarted")}
      </Typography>

      <Typography color={colors.black2} textAlign="center" variant="large">
        <Trans
          i18nKey="registerAsPerson"
          components={[
            <ClickableLabel key="1" onClick={() => navigate("/register")} />,
          ]}
        />
      </Typography>
      <Box marginBottom="12px" />
      <Controller
        name="companyName"
        rules={{ required: t("required") }}
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            fullWidth
            value={value}
            onChange={onChange}
            label={t("company")}
            error={!!errors?.companyName}
            helperText={errors?.companyName?.message}
            disabled={isPending}
          />
        )}
      />
      <Box marginBottom="12px" />
      <Box display="flex" gap="12px">
        <Controller
          name="address.street"
          control={control}
          rules={{ required: t("required") }}
          render={({ field: { value, onChange } }) => (
            <TextField
              fullWidth
              label={t("street")}
              value={value}
              onChange={onChange}
              error={!!errors.address?.street}
              helperText={errors.address?.street?.message}
              sx={{ flex: 3 }}
            />
          )}
        />
        <Controller
          name="houseNumber"
          control={control}
          rules={{ required: t("required") }}
          render={({ field: { value, onChange } }) => (
            <TextField
              fullWidth
              label={t("houseNumber")}
              value={value}
              onChange={onChange}
              error={!!errors.houseNumber}
              helperText={errors.houseNumber?.message}
              sx={{ flex: 2 }}
            />
          )}
        />
      </Box>

      <Box marginBottom="12px" />
      <PlaceController
        formControl={control as unknown as Control<FieldValues>}
        field={
          {
            controlName: "address.postCodePlace",
            label: t("postalCodeOrCity"),
          } as any
        }
        disabled={false}
        rules={{ required: t("required") }}
      />

      <Typography variant="h6" marginTop="24px">
        {t("contactPerson")}
      </Typography>
      <Box marginBottom="24px" />
      <Box display="flex" justifyContent="space-between" gap="12px">
        <Controller
          name="firstName"
          control={control}
          rules={{
            required: t("required"),
          }}
          render={({ field: { value, onChange } }) => (
            <TextField
              fullWidth
              value={value}
              onChange={onChange}
              label={t("firstName")}
              error={!!errors?.firstName}
              helperText={errors?.firstName?.message}
              disabled={isPending}
              data-cy="first-name"
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          rules={{ required: t("required") }}
          render={({ field: { value, onChange } }) => (
            <TextField
              fullWidth
              value={value}
              onChange={onChange}
              label={t("lastName")}
              error={!!errors?.lastName}
              helperText={errors?.lastName?.message}
              disabled={isPending}
              data-cy="last-name"
            />
          )}
        />
      </Box>
      <Box marginBottom="12px" />
      <Controller
        name="email"
        control={control}
        rules={{
          required: t("required"),
          pattern: {
            value: EMAIL_REGEX_VALIDATION,
            message: t("emailNotValid"),
          },
        }}
        render={({ field: { value, onChange } }) => (
          <TextField
            fullWidth
            value={value}
            onChange={onChange}
            label={t("email")}
            error={!!errors?.email}
            helperText={errors?.email?.message}
            disabled={isPending}
            data-cy="email"
          />
        )}
      />
      <Box marginBottom="12px" />
      <Controller
        name="phoneNumber"
        control={control}
        rules={{
          required: t("required"),
          validate: validatePhoneNumber,
        }}
        render={({ field: { value, onChange } }) => (
          <PhoneNumberInput
            fullWidth
            value={value}
            onChange={onChange}
            label={t("mobilePhone")}
            error={!!errors?.phoneNumber}
            helperText={errors?.phoneNumber?.message}
            disabled={isPending}
            data-cy="phone-number"
          />
        )}
      />
      <Box marginBottom="12px" />

      <FormControl fullWidth variant="outlined" error={!!errors.accountType}>
        <InputLabel>{t("accountType")}</InputLabel>
        <Controller
          name="accountType"
          control={control}
          rules={{ required: t("required") }}
          render={({ field: { value, onChange } }) => (
            <Select
              label={t("accountType")}
              value={value}
              onChange={onChange}
              fullWidth
            >
              <MenuItem value="Autohaus">{t("autohaus")}</MenuItem>
              <MenuItem value="CarPool">{t("carpool")}</MenuItem>
              <MenuItem value="Expert">{t("expert")}</MenuItem>
              <MenuItem value="Workshop">{t("workshop")}</MenuItem>
            </Select>
          )}
        />
        {errors.accountType && (
          <Typography color="error">{t("required")}</Typography>
        )}
      </FormControl>

      <Box marginBottom="12px" />
      <Controller
        name="password"
        control={control}
        rules={{
          required: t("required"),
          pattern: {
            value: PASSWORD_REGEX_VALIDATION,
            message: t("passwordNotValid"),
          },
        }}
        render={({ field: { value, onChange } }) => (
          <TextField
            fullWidth
            value={value}
            type="password"
            onChange={onChange}
            label={t("password")}
            error={!!errors?.password}
            helperText={errors?.password?.message}
            disabled={isPending}
            data-cy="password"
          />
        )}
      />
      <Box marginBottom="12px" />
      <Controller
        name="confirmPassword"
        control={control}
        rules={{
          required: t("required"),
          validate: validateConfirmPassword,
        }}
        render={({ field: { value, onChange } }) => (
          <TextField
            fullWidth
            value={value}
            type="password"
            onChange={onChange}
            label={t("confirmPassword")}
            error={!!errors?.confirmPassword}
            helperText={errors?.confirmPassword?.message}
            disabled={isPending}
            data-cy="confirm-password"
          />
        )}
      />

      <Box marginTop="24px">
        <Typography color={colors.black2}>
          <Trans
            i18nKey="crashMateRegisterOrganizationInfo"
            components={[
              <Typography
                key="1"
                variant="regularBold"
                color={colors.black2}
              />,
            ]}
          />
        </Typography>
      </Box>

      <Box marginBottom="12px" />
      <Controller
        name="dataProcessingAccepted"
        control={control}
        rules={{
          required: t("required"),
        }}
        render={({ field: { value, onChange } }) => (
          <Box display="flex" gap="8px">
            <Checkbox
              checked={value}
              onChange={onChange}
              disabled={isPending}
            />
            <Typography color={colors.black2}>
              <Trans
                i18nKey="dataProcessingAgreement"
                components={[
                  <ClickableLabel
                    key="1"
                    href="https://www.werner-kollegen.com/agb/"
                    variant="regularBold"
                    target="_blank"
                  />,
                ]}
              />
            </Typography>
            {errors.dataProcessingAccepted && (
              <Typography color="error">{t("required")}</Typography>
            )}
          </Box>
        )}
      />

      <Box marginBottom="12px" />
      <Controller
        name="termsAccepted"
        control={control}
        rules={{
          required: t("required"),
        }}
        render={({ field: { value, onChange } }) => (
          <Box display="flex" gap="8px">
            <Checkbox
              checked={value}
              onChange={onChange}
              disabled={isPending}
            />
            <Typography color={colors.black2}>
              <Trans
                i18nKey="agreeToTerms"
                components={[
                  <ClickableLabel
                    key="1"
                    href="https://www.werner-kollegen.com/agb/"
                    variant="regularBold"
                    target="_blank"
                  />,
                ]}
              />
            </Typography>
            {errors.termsAccepted && (
              <Typography color="error">{t("required")}</Typography>
            )}
          </Box>
        )}
      />
      <Box marginBottom="12px" />
      <Controller
        name="privacyAccepted"
        control={control}
        rules={{
          required: t("required"),
        }}
        render={({ field: { value, onChange } }) => (
          <Box display="flex" gap="8px">
            <Checkbox
              checked={value}
              onChange={onChange}
              disabled={isPending}
            />
            <Typography color={colors.black2}>
              <Trans
                i18nKey="agreeToPrivacy"
                components={[
                  <ClickableLabel
                    key="2"
                    href="https://www.werner-kollegen.com/datenschutz/"
                    variant="regularBold"
                    target="_blank"
                  />,
                ]}
              />
            </Typography>
            {errors.privacyAccepted && (
              <Typography color="error">{t("required")}</Typography>
            )}
          </Box>
        )}
      />

      <Box marginTop="32px" marginBottom="16px">
        <LoadingButton fullWidth type="submit" loading={isPending}>
          {t("completeRegistration")}
        </LoadingButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography color={colors.black2}>
          <Trans
            i18nKey="haveAccountPrompt"
            components={[
              <ClickableLabel
                onClick={onBackToLoginClick}
                key="1"
                variant="regularBold"
              />,
            ]}
          />
        </Typography>
      </Box>

      <Box position="fixed" bottom="10px" right="10px">
        <ClickableLabel
          href="https://www.werner-kollegen.com/impressum/"
          variant="smallBold"
          target="_blank"
        >
          {t("impressum")}
        </ClickableLabel>
      </Box>
    </Box>
  )
}
