import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"
import {
  DashboardPage,
  MyProfilePage,
  SecurityPage,
  LoginSettingsPage,
  CompanyDetailsPage,
  ClaimPage,
  ClaimsListPage,
  WorkflowConfigurator,
  DocumentPreviewPage,
  TrackingLandingPage,
  TrackingLegalNotice,
  TrackingPrivacyPolicy,
  SignatureSettingsPage,
  TrackingClaimsPage,
  GroupManagementPage,
  UserManagementPage,
  WorkflowDefinitionsListingPage,
  WorkflowListingPage,
  WorkflowPreview,
  TrackingContactForm,
  OrganizationManagementPage,
  UnderDevelopment,
  InvitationVerificationPage,
} from "../../../pages"
import { InactivityProvider, useAppContext } from "../../../contexts"
import { BaseLayout, SettingsLayout } from "./layouts"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  canViewGroupDetails,
  EDIT_CLAIM_PATHNAME_REGEX,
  getLanguageCode,
} from "../../../utils"
import { useQuery } from "@tanstack/react-query"
import { changeLanguage } from "i18next"
import { TrackingLayout } from "../layouts"
import { GlobalInboxPage } from "../../../pages/GlobalInboxPage/GlobalInboxPage"

const RouterRoutes = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { state: appState } = useAppContext()
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const hasAdministrationPermission = useMemo(
    () =>
      !!user?.permissions?.some(
        (permission) =>
          permission?.scope === "Group" &&
          permission?.objectId === appState.groupId &&
          permission?.action === "GROUP_DETAILS" &&
          permission?.access === "Delete",
      ),
    [user, appState.groupId],
  )
  const hasPermissionForCarManagement = useMemo(
    () =>
      user?.permissions?.some(
        (p) =>
          p.action === "CAR_MANAGEMENT" &&
          p.access === "Delete" &&
          user.companyType === "CarPool",
      ),
    [user],
  )

  const isSuperAdmin = useMemo(() => canViewGroupDetails(user), [user])

  const isProduction = process.env.REACT_APP_NAME === "PRODUCTION"
  const isStaging = process.env.REACT_APP_NAME === "STAGING"

  useEffect(() => {
    let title = t("crashMate")

    if (pathname === "/dashboard") {
      title = `${t("crashMate")} - ${t("dashboard")}`
    } else if (pathname === "/users") {
      title = `${t("crashMate")} - ${t("users")}`
    } else if (pathname === "/claims") {
      title = `${t("crashMate")} - ${t("claimListing")}`
    } else if (pathname?.startsWith("/settings/")) {
      title = `${t("crashMate")} - ${t("settings")}`
    } else if (EDIT_CLAIM_PATHNAME_REGEX.test(pathname)) {
      // const claimId = pathname?.split("/")?.[2]
      // title = claimId ? `${t("crashMate")} - ${claimId}` : t("crashMate")
      title = t("newClaim")
    } else if (pathname.includes("tracking-overview")) {
      title = t("trackingOverview")
    } else {
      title = t("crashMate")
    }

    document.title = title
  }, [pathname, t])

  useEffect(() => {
    void changeLanguage(getLanguageCode(user?.userSettings.language!))
  }, [user])

  return (
    <Routes>
      <Route path="dashboard" element={<DashboardPage />} />
      <Route path="claims" element={<ClaimsListPage />} />
      <Route
        path="settings/*"
        element={
          <SettingsLayout>
            <Routes>
              <Route path="my-profile" element={<MyProfilePage />} />
              <Route path="security" element={<SecurityPage />} />
              <Route path="login-settings" element={<LoginSettingsPage />} />
              <Route path="company-details" element={<CompanyDetailsPage />} />
              <Route path="signature" element={<SignatureSettingsPage />} />
              <Route index element={<Navigate to="my-profile" replace />} />
            </Routes>
          </SettingsLayout>
        }
      />
      <Route path="claims/:id" element={<ClaimPage key="update-claim" />} />
      <Route
        path="document-preview"
        element={<DocumentPreviewPage key="document-preview" />}
      />
      {(isSuperAdmin || hasAdministrationPermission) && (
        <>
          <Route
            path="organization-management"
            element={<OrganizationManagementPage />}
          />
          <Route path="group-management" element={<GroupManagementPage />} />
          <Route path="user-management" element={<UserManagementPage />} />
          <Route path="reporting" element={<UnderDevelopment />} />
        </>
      )}
      {hasPermissionForCarManagement && (
        <Route path="car-management" element={<UnderDevelopment />} />
      )}
      {!isProduction && !isStaging && (
        <>
          <Route path="global-inbox" element={<GlobalInboxPage />} />
          <Route path="workflow-overview" element={<WorkflowListingPage />} />
          <Route
            path="workflow-definitions"
            element={<WorkflowDefinitionsListingPage />}
          />
          <Route
            path="workflow-configurator/:id"
            element={<WorkflowConfigurator />}
          />
          <Route
            path="workflow-preview/:workflowId/:definitionId"
            element={<WorkflowPreview />}
          />
        </>
      )}
      <Route
        path="public/tracking-overview/*"
        element={
          <TrackingLayout>
            <Routes>
              <Route path="" element={<TrackingLandingPage />} />
              <Route path="claims" element={<TrackingClaimsPage />} />
              <Route path="start-new-query" element={<TrackingLandingPage />} />
              <Route path="contact-form" element={<TrackingContactForm />} />
              <Route path="legal-notice" element={<TrackingLegalNotice />} />
              <Route
                path="privacy-policy"
                element={<TrackingPrivacyPolicy />}
              />
            </Routes>
          </TrackingLayout>
        }
      />
      <Route
        path="invitation-verification"
        element={<InvitationVerificationPage />}
      />
      <Route path="*" element={<Navigate to="dashboard" replace />} />
    </Routes>
  )
}

export const AuthorizedRouter = () => {
  return (
    <InactivityProvider>
      <BrowserRouter>
        <BaseLayout>
          <RouterRoutes />
        </BaseLayout>
      </BrowserRouter>
    </InactivityProvider>
  )
}
