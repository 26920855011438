import {
  Box,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import {
  EMAIL_REGEX_VALIDATION,
  PASSWORD_REGEX_VALIDATION,
  colors,
} from "../../utils"
import { ClickableLabel, PhoneNumberInput } from "../../components"
import { type FieldValues, useForm, Controller } from "react-hook-form"
import { useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { resendEmailAPI, signupAPI } from "../../services"
import { LoadingButton } from "@mui/lab"
import { ReactComponent as EmailVerifiedIcon } from "../../assets/icons/email-verified.svg"
import { useAppContext, useToast } from "../../contexts"
import { isValidPhoneNumber } from "react-phone-number-input"
import { ReactComponent as CrashMateLogo } from "../../assets/icons/crashmate-logo.svg"
import { CustomLink } from "./styled"

export const RegisterPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toast = useToast()
  const { mutateRegisterInvitation } = useAppContext()
  const location = useLocation()
  const { invitationId, email } = location.state || {}

  const hasInvitation = Boolean(invitationId && email)

  const { mutate: mutateSignupInvitation, isPending: pendingSignupInvitation } =
    mutateRegisterInvitation

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      termsAccepted: false,
      privacyAccepted: false,
    },
  })

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: async (body: ISignupRequestBody) => await signupAPI(body),
  })

  const { mutate: mutateResendEmail, isPending: pendingResend } = useMutation({
    mutationFn: async (body: string) => await resendEmailAPI(body),
    onSuccess: () => {
      toast.show(t("emailSent"), "success")
    },
  })

  const passwordValue = watch("password")

  const validatePhoneNumber = useCallback((value: string) => {
    if (!isValidPhoneNumber(value)) {
      return t("invalidPhoneNumberErrorMessage")
    } else {
      return true
    }
  }, [])

  const validateConfirmPassword = useCallback(
    (value: string) => {
      if (value === passwordValue) {
        return true
      } else {
        return t("passwordsDoNotMatch")
      }
    },
    [passwordValue],
  )

  const onSubmit = useCallback(
    (data: FieldValues) => {
      const { termsAccepted: _, privacyAccepted: __, ...filteredData } = data

      if (hasInvitation) {
        const { email: _email, ...restData } = filteredData
        const invitationData = {
          ...restData,
          invitationId: invitationId,
        }
        mutateSignupInvitation(invitationData as ISignupInvitationRequestBody)
      } else {
        mutate(filteredData as ISignupRequestBody)
      }
    },
    [hasInvitation, invitationId, mutateSignupInvitation, mutate],
  )

  const onResendClick = useCallback(() => {
    mutateResendEmail(watch("email"))
  }, [])

  const onBackToLoginClick = useCallback(() => navigate("../login"), [])

  if (pendingResend) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography marginBottom="24px" variant="h5" color={colors.primary}>
          {t("resendingEmail")}
        </Typography>
        <CircularProgress />
      </Box>
    )
  }

  if (isSuccess) {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <EmailVerifiedIcon />
        <Box marginBottom="16px" />
        <Typography marginY="8px" variant="h3" textAlign="center">
          {t("verifyYourAddress")}
        </Typography>
        <Typography variant="large" color={colors.black2} textAlign="center">
          <Trans
            i18nKey="accountActivationDescription"
            components={[
              <ClickableLabel
                key="1"
                variant="largeBold"
                disabled={isPending}
                onClick={onResendClick}
              />,
            ]}
          />
        </Typography>
        <Typography marginTop="16px" color={colors.black2}>
          <Trans
            i18nKey="backToLogin"
            components={[
              <ClickableLabel
                key="1"
                variant="regularBold"
                onClick={onBackToLoginClick}
              />,
            ]}
          />
        </Typography>
      </Box>
    )
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <CrashMateLogo />
      <Typography marginY="8px" variant="h3">
        {t("getStarted")}
      </Typography>
      {!hasInvitation && (
        <Typography color={colors.black2} textAlign="center" variant="large">
          <Trans
            i18nKey="registerAsOrganization"
            components={[
              <ClickableLabel
                key="1"
                onClick={() => navigate("/organisation-register")}
              />,
            ]}
          />
        </Typography>
      )}
      <Box marginBottom="24px" />
      <Box display="flex" justifyContent="space-between" gap="12px">
        <Controller
          name="firstName"
          control={control}
          rules={{
            required: t("required"),
          }}
          render={({ field: { value, onChange } }) => (
            <TextField
              fullWidth
              value={value}
              onChange={onChange}
              label={t("firstName")}
              error={!!errors?.firstName}
              helperText={errors?.firstName?.message}
              disabled={isPending}
              data-cy="first-name"
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          rules={{ required: t("required") }}
          render={({ field: { value, onChange } }) => (
            <TextField
              fullWidth
              value={value}
              onChange={onChange}
              label={t("lastName")}
              error={!!errors?.lastName}
              helperText={errors?.lastName?.message}
              disabled={isPending}
              data-cy="last-name"
            />
          )}
        />
      </Box>
      <Box marginBottom="12px" />
      <Controller
        name="email"
        control={control}
        rules={{
          required: hasInvitation ? false : t("required"),
          pattern: {
            value: EMAIL_REGEX_VALIDATION,
            message: t("emailNotValid"),
          },
        }}
        render={({ field: { value, onChange } }) => (
          <TextField
            fullWidth
            value={hasInvitation ? email : value}
            onChange={onChange}
            label={t("email")}
            error={!!errors?.email}
            helperText={errors?.email?.message}
            disabled={isPending || hasInvitation}
            data-cy="email"
          />
        )}
      />
      <Box marginBottom="12px" />
      <Controller
        name="phoneNumber"
        control={control}
        rules={{
          required: t("required"),
          validate: validatePhoneNumber,
        }}
        render={({ field: { value, onChange } }) => (
          <PhoneNumberInput
            fullWidth
            value={value}
            onChange={onChange}
            label={t("mobilePhone")}
            error={!!errors?.phoneNumber}
            helperText={errors?.phoneNumber?.message}
            disabled={isPending}
            data-cy="phone-number"
          />
        )}
      />
      <Box marginBottom="12px" />
      <Controller
        name="password"
        control={control}
        rules={{
          required: t("required"),
          pattern: {
            value: PASSWORD_REGEX_VALIDATION,
            message: t("passwordNotValid"),
          },
        }}
        render={({ field: { value, onChange } }) => (
          <TextField
            fullWidth
            value={value}
            type="password"
            onChange={onChange}
            label={t("password")}
            error={!!errors?.password}
            helperText={errors?.password?.message}
            disabled={isPending}
            data-cy="password"
          />
        )}
      />
      <Box marginBottom="12px" />
      <Controller
        name="confirmPassword"
        control={control}
        rules={{
          required: t("required"),
          validate: validateConfirmPassword,
        }}
        render={({ field: { value, onChange } }) => (
          <TextField
            fullWidth
            value={value}
            type="password"
            onChange={onChange}
            label={t("confirmPassword")}
            error={!!errors?.confirmPassword}
            helperText={errors?.confirmPassword?.message}
            disabled={isPending}
            data-cy="confirm-password"
          />
        )}
      />
      <Box marginBottom="24px" />

      <Box marginTop="24px">
        <Typography color={colors.black2}>
          <Trans
            i18nKey="crashMateRegisterInfo"
            components={[
              <Typography
                key="1"
                variant="regularBold"
                color={colors.black2}
              />,
              <CustomLink
                key="2"
                href="https://www.werner-kollegen.com"
                variant="regularBold"
                target="_blank"
              />,
              <CustomLink
                key="3"
                href="https://crashmate.de/faq"
                variant="regularBold"
                target="_blank"
              />,
            ]}
          />
        </Typography>
      </Box>
      <Box marginBottom="12px" />
      <Controller
        name="termsAccepted"
        control={control}
        rules={{
          required: t("required"),
        }}
        render={({ field: { value, onChange } }) => (
          <Box display="flex" gap="8px">
            <Checkbox
              checked={value}
              onChange={onChange}
              disabled={isPending}
            />
            <Typography color={colors.black2}>
              <Trans
                i18nKey="agreeToTerms"
                components={[
                  <ClickableLabel
                    key="1"
                    href="https://www.werner-kollegen.com/agb/"
                    variant="regularBold"
                    target="_blank"
                  />,
                ]}
              />
            </Typography>
            {errors.termsAccepted && (
              <Typography color="error">{t("required")}</Typography>
            )}
          </Box>
        )}
      />
      <Box marginBottom="12px" />
      <Controller
        name="privacyAccepted"
        control={control}
        rules={{
          required: t("required"),
        }}
        render={({ field: { value, onChange } }) => (
          <Box display="flex" gap="8px">
            <Checkbox
              checked={value}
              onChange={onChange}
              disabled={isPending}
            />
            <Typography color={colors.black2}>
              <Trans
                i18nKey="agreeToPrivacy"
                components={[
                  <ClickableLabel
                    key="2"
                    href="https://www.werner-kollegen.com/datenschutz/"
                    variant="regularBold"
                    target="_blank"
                  />,
                ]}
              />
            </Typography>
            {errors.privacyAccepted && (
              <Typography color="error">{t("required")}</Typography>
            )}
          </Box>
        )}
      />
      <Box marginTop="32px" marginBottom="16px">
        <LoadingButton
          fullWidth
          type="submit"
          loading={isPending || pendingSignupInvitation}
          data-cy="sign-up"
        >
          {t("completeRegistration")}
        </LoadingButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography color={colors.black2}>
          <Trans
            i18nKey="haveAccountPrompt"
            components={[
              <ClickableLabel
                onClick={onBackToLoginClick}
                key="1"
                variant="regularBold"
              />,
            ]}
          />
        </Typography>
      </Box>
      <Box position="fixed" bottom="10px" right="10px">
        <ClickableLabel
          href="https://www.werner-kollegen.com/impressum/"
          variant="smallBold"
          target="_blank"
        >
          {t("impressum")}
        </ClickableLabel>
      </Box>
    </Box>
  )
}
