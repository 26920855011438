import React, { useState, useMemo } from "react"
import dayjs from "dayjs"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TableSortLabel,
  IconButton,
  Tooltip,
} from "@mui/material"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { AlertDialog } from "../../../../components"
import { deleteInvitationAPI, resendInvitationAPI } from "../../../../services"
import { useToast } from "../../../../contexts"
import { useTranslation } from "react-i18next"

interface Invitation {
  email: string
  firstName: string | null
  lastName: string | null
  status: string
  dateInvitationSent: string
  invitationExpiresOn: string
}

interface InvitationsTableProps {
  invitations: Invitation[]
  groupId: string | undefined
}

export const InvitationsTable: React.FC<InvitationsTableProps> = ({
  invitations,
  groupId,
}) => {
  const [filters, setFilters] = useState({ pageSize: 10, page: 0 })
  const [sortBy, setSortBy] = useState<keyof Invitation>("email")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc")
  const queryClient = useQueryClient()
  const toast = useToast()
  const { t } = useTranslation()

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [emailToDelete, setEmailToDelete] = useState<string | null>(null)

  const handleSort = (column: keyof Invitation) => {
    setSortBy(column)
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
  }

  const sortedInvitations = useMemo(() => {
    const orderMultiplier = sortOrder === "asc" ? 1 : -1
    return [...invitations].sort((a, b) => {
      const aValue = a[sortBy] ?? ""
      const bValue = b[sortBy] ?? ""

      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue) * orderMultiplier
      }
      return 0
    })
  }, [invitations, sortBy, sortOrder])

  const paginatedInvitations = useMemo(() => {
    const start = filters.page * filters.pageSize
    return sortedInvitations.slice(start, start + filters.pageSize)
  }, [sortedInvitations, filters])

  const onPageChange = (_: unknown, page: number) =>
    setFilters((prev) => ({ ...prev, page }))
  const onPageSizeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setFilters((prev) => ({ ...prev, pageSize: +event.target.value }))

  const { mutate: resendInvitation } = useMutation({
    mutationFn: (payload: IResendInvitationParams) => {
      return resendInvitationAPI(payload)
    },
    onSuccess: () => {
      toast.show(t("invitationResent"), "success")
    },
    onError: () => {
      toast.show(t("invitationResentFailed"), "error")
    },
  })

  const { mutate: deleteInvitation, isPending: isDeleting } = useMutation({
    mutationFn: (payload: IResendInvitationParams) =>
      deleteInvitationAPI(payload),
    onSuccess: () => {
      toast.show(t("invitationDeleted"), "success")
      void queryClient.refetchQueries({
        queryKey: ["groupUsers"],
      })
      setDeleteDialogOpen(false)
      setEmailToDelete(null)
    },
    onError: () => {
      toast.show(t("invitationDeletionFailed"), "error")
      setDeleteDialogOpen(false)
    },
  })

  const handleDeleteClick = (email: string) => {
    setEmailToDelete(email)
    setDeleteDialogOpen(true)
  }

  const handleConfirmDelete = () => {
    if (emailToDelete && groupId) {
      deleteInvitation({ groupId, userEmail: emailToDelete })
    }
  }

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false)
    setEmailToDelete(null)
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortBy === "email"}
                direction={sortOrder}
                onClick={() => handleSort("email")}
              >
                {t("email")}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === "status"}
                direction={sortOrder}
                onClick={() => handleSort("status")}
              >
                {t("status")}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === "dateInvitationSent"}
                direction={sortOrder}
                onClick={() => handleSort("dateInvitationSent")}
              >
                {t("dateInvitationSent")}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === "invitationExpiresOn"}
                direction={sortOrder}
                onClick={() => handleSort("invitationExpiresOn")}
              >
                {t("invitationExpiresOn")}
              </TableSortLabel>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedInvitations.map((invitation) => (
            <TableRow key={invitation.email}>
              <TableCell>{invitation.email}</TableCell>
              <TableCell>{t(invitation.status)}</TableCell>
              <TableCell>
                {dayjs(invitation.dateInvitationSent).format(
                  "DD.MM.YYYY HH:mm",
                )}
              </TableCell>
              <TableCell>
                {dayjs(invitation.invitationExpiresOn).format(
                  "DD.MM.YYYY HH:mm",
                )}
              </TableCell>
              <TableCell>
                <Tooltip title={t("resendInvitation")}>
                  <IconButton
                    onClick={() => {
                      groupId &&
                        resendInvitation({
                          groupId,
                          userEmail: invitation.email,
                        })
                    }}
                  >
                    <NotificationsActiveOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title={t("deleteInvitation")}>
                  <IconButton
                    onClick={() => handleDeleteClick(invitation.email)}
                  >
                    <DeleteOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={invitations.length}
              page={filters.page}
              rowsPerPage={filters.pageSize}
              onPageChange={onPageChange}
              onRowsPerPageChange={onPageSizeChange}
              labelRowsPerPage={t("rowsPerPage")}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <AlertDialog
        isVisible={deleteDialogOpen}
        message={t("areYouSureYouWantToDeleteInvitation")}
        confirmLabel="Delete"
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        loading={isDeleting}
      />
    </TableContainer>
  )
}
