import { useState, useCallback, useMemo, type ChangeEventHandler } from "react"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
  IconButton,
  Skeleton,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  TableSortLabel,
  Switch,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  getGroupUsersAPI,
  getOrganizationsAPI,
  deleteUserAPI,
  getUserByRoleAPI,
  removeUserRoleAPI,
} from "../../services"
import { useAppContext, useToast } from "../../contexts"
import { colors } from "../../utils/colors"
import { AUTHORIZED_CONTENT_MAX_WIDTH } from "../../utils"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { LoadingButton } from "@mui/lab"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import { AlertDialog, InviteUserDialog } from "../../components"
import UpdateUserRolesDialog from "../../components/UpdateUserRolesDialog/UpdateUserRolesDialog"
import { Add, Search } from "@mui/icons-material"
import { StyledSelect } from "./styled"
import { AssignUserDialog } from "../../components/AssignUserModal"
import { InvitationsTable } from "./components/InvitationsTable/InvitationsTable"

export const UserManagementPage = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()

  const organizationsQueryParams = {
    includeDisabledGroups: false,
    groupManagementTab: false,
    ownedGroups: true,
  }

  const [filters, setFilters] = useState({ pageSize: 10, page: 0 })
  const { state: appState } = useAppContext()
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(
    appState.groupId,
  )
  const [selectedRole, setSelectedRole] = useState<string | undefined>(
    "SuperAdmin",
  )

  const { breakpoints } = useTheme()
  const isSmallerThanLg = useMediaQuery(breakpoints.down("lg"))
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null)
  const [isUpdateRolesDialogOpen, setIsUpdateRolesDialogOpen] = useState(false)
  const [predefinedUser, setPredefinedUser] = useState<string>("")
  const [showInvitations, setShowInvitations] = useState(false)
  const [selectedUserForRoles, setSelectedUserForRoles] = useState<{
    userId: string
    userRoles: { claimRoles: string[]; groupRole: string }
  } | null>(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [tabIndex, setTabIndex] = useState(0)
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false)
  const [isAssignUserDialogOpen, setIsAssignUserDialogOpen] = useState(false)
  const [sortBy, setSortBy] = useState("name")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc")

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const hasPermissionForSystemRoles = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "ROLE_PERMISSIONS_CONFIG" && p.access === "Delete",
      ),
    [user],
  )

  const handleTabChange = (_: any, newValue: number) => {
    setTabIndex(newValue)
    setFilters({ pageSize: 10, page: 0 })
    setSelectedGroup(undefined)
    setSearchTerm("")
    setShowInvitations(false)
    if (newValue === 1) {
      setSelectedRole("SuperAdmin")
    } else {
      setSelectedRole(undefined)

      setSelectedGroup(appState.groupId)
    }
  }

  const {
    data: groupData,
    isLoading: isGroupLoading,
    isRefetching: isGroupRefetching,
  } = useQuery({
    queryKey: ["groupUsers", selectedGroup],
    queryFn: () => getGroupUsersAPI({ groupId: selectedGroup }),
    enabled: tabIndex === 0,
    refetchOnMount: true,
  })

  const groupUsers = groupData?.groupUsers
  const invitations = groupData?.invitations

  const handleShowInvitationsToggle = () => {
    setShowInvitations((prev) => !prev)
  }

  const {
    data: usersByRole,
    isLoading: isLoadingUsersByRole,
    isRefetching: isRefetchingUsersByRole,
  } = useQuery({
    queryKey: ["usersByRole", selectedRole],
    queryFn: () => getUserByRoleAPI(selectedRole),
    enabled: tabIndex === 1 && !!selectedRole,
  })
  const { mutate: removeUserRole } = useMutation({
    mutationFn: ({ userId, roleType }: { userId: string; roleType: string }) =>
      removeUserRoleAPI(userId, roleType),
    onSuccess: () => {
      void queryClient.refetchQueries({
        queryKey: ["usersByRole", selectedRole],
      })
      toast.show(t("systemRoleRemoved"), "success")
    },
    onError: () => {
      toast.show(t("errorRemovingRole"), "error")
    },
  })

  const { data: organizations } = useQuery({
    queryKey: ["organizations-disabled-managed", organizationsQueryParams],
    queryFn: () => getOrganizationsAPI(organizationsQueryParams),
    refetchOnMount: true,
  })

  const filteredUsers = useMemo(() => {
    const users = tabIndex === 0 ? groupUsers : usersByRole
    if (users) {
      const searchWords = searchTerm.toLowerCase().split(" ").filter(Boolean)
      return users.filter((user: any) =>
        searchWords.every(
          (word) =>
            user?.firstName?.toLowerCase().includes(word) ||
            user?.lastName?.toLowerCase().includes(word) ||
            user?.email?.toLowerCase().includes(word),
        ),
      )
    }
    return []
  }, [groupUsers, usersByRole, searchTerm, tabIndex])

  const sortedUsers = useMemo(() => {
    if (!filteredUsers) return []

    const orderMultiplier = sortOrder === "asc" ? 1 : -1

    return [...filteredUsers].sort((a, b) => {
      if (sortBy === "name") {
        const nameA = `${a.firstName ?? ""} ${a.lastName ?? ""}`
          .trim()
          .toLowerCase()
        const nameB = `${b.firstName ?? ""} ${b.lastName ?? ""}`
          .trim()
          .toLowerCase()
        return nameA.localeCompare(nameB) * orderMultiplier
      } else if (sortBy === "email") {
        return (
          (a.email?.toLowerCase() ?? "").localeCompare(
            b.email?.toLowerCase() ?? "",
          ) * orderMultiplier
        )
      }
      return 0
    })
  }, [filteredUsers, sortBy, sortOrder])

  const paginatedUsers = useMemo(() => {
    if (sortedUsers) {
      return sortedUsers.slice(
        filters.page * filters.pageSize,
        (filters.page + 1) * filters.pageSize,
      )
    }
    return undefined
  }, [sortedUsers, filters])

  const onPageChange = useCallback(
    (_: unknown, page: number) => setFilters((prev) => ({ ...prev, page })),
    [],
  )

  const onPageSizeChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event: any) =>
      setFilters((prev) => ({ ...prev, pageSize: +event.target.value })),
    [],
  )
  const handleOpenInviteUserModal = () => setIsInviteUserModalOpen(true)
  const handleCloseInviteUserModal = () => setIsInviteUserModalOpen(false)

  const handleOpenAssignUserDialog = () => setIsAssignUserDialogOpen(true)
  const handleCloseAssignUserDialog = () => setIsAssignUserDialogOpen(false)

  const { mutate: deleteUser, isPending: isDeleting } = useMutation({
    mutationFn: ({ groupId, userId }: { groupId: string; userId: string }) =>
      deleteUserAPI(groupId, userId),
    onSuccess: (_, { groupId, userId }) => {
      queryClient.setQueryData<any>(["groupUsers", groupId], (old: any) => {
        return {
          ...old,
          groupUsers:
            old?.groupUsers?.filter((user: any) => user.userId !== userId) ||
            [],
        }
      })

      queryClient.setQueryData<any[]>(["users"], (old) => {
        return (
          old?.map((org) => ({
            ...org,
            users: org.users.filter((user: any) => user.userId !== userId),
          })) || []
        )
      })

      toast.show(t("userDeleted"), "success")
      setDeleteDialogOpen(false)
    },
    onError: () => {
      setDeleteDialogOpen(false)
    },
  })

  const handleConfirmDelete = () => {
    if (userIdToDelete) {
      if (tabIndex === 0 && selectedGroup) {
        deleteUser({ groupId: selectedGroup, userId: userIdToDelete })
      } else if (tabIndex === 1 && selectedRole) {
        removeUserRole({ userId: userIdToDelete, roleType: selectedRole })
      }

      setUserIdToDelete(null)
    }
  }

  const handleDeleteClick = (userId: string) => {
    setUserIdToDelete(userId)
    setDeleteDialogOpen(true)
  }

  const handleCancelDelete = () => {
    setUserIdToDelete(null)
    setDeleteDialogOpen(false)
  }

  const handleEditClick = (
    userId: string,
    userRoles: { claimRoles: string[]; groupRole: string },
  ) => {
    setSelectedUserForRoles({ userId, userRoles })
    setIsUpdateRolesDialogOpen(true)
  }
  const handleAssignClick = (userId: string) => {
    setPredefinedUser(userId)
  }
  const handleCloseUpdateRolesDialog = () => {
    setIsUpdateRolesDialogOpen(false)
    setSelectedUserForRoles(null)
  }

  const handleSort = (column: string) => {
    setSortBy(column)
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
  }

  const sortedRoles = useMemo(() => {
    return [
      { value: "SuperAdmin", label: t("superAdmin") },
      { value: "Clerk", label: t("clerk") },
    ].sort((a, b) => a.label.localeCompare(b.label))
  }, [t])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      padding="24px"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        gap="8px"
      >
        <Box
          display="flex"
          gap={isSmallerThanLg ? "8px" : "16px"}
          flexDirection={isSmallerThanLg ? "column" : "row"}
          alignItems={isSmallerThanLg ? "" : "center"}
          marginBottom="24px"
        >
          <Typography flex={1} variant="h4" paddingRight="16px">
            {t("userManagement")}
          </Typography>

          {tabIndex === 0 && (
            <FormControl>
              <InputLabel>{t("selectGroup")}</InputLabel>
              <StyledSelect
                fullWidth={isSmallerThanLg}
                value={selectedGroup || ""}
                onChange={(e: any) => {
                  setFilters({ pageSize: 10, page: 0 })
                  setSelectedGroup(e.target.value || undefined)
                }}
                label={t("selectGroup")}
              >
                <MenuItem value="">
                  <em>{t("noGroup")}</em>
                </MenuItem>
                {organizations
                  ?.flatMap((organization) => organization.groups ?? [])
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
              </StyledSelect>
            </FormControl>
          )}

          {tabIndex === 1 && (
            <FormControl
              style={{
                marginTop: isSmallerThanLg ? "10px" : "0px",
                marginBottom: isSmallerThanLg ? "10px" : "0px",
              }}
            >
              <InputLabel>{t("selectRole")}</InputLabel>
              <StyledSelect
                fullWidth={isSmallerThanLg}
                value={selectedRole || ""}
                onChange={(e: any) => {
                  setSelectedRole(e.target.value || undefined)
                  setSelectedGroup(undefined)
                  setFilters({ pageSize: 10, page: 0 })
                }}
                label={t("selectRole")}
              >
                {sortedRoles.map((role) => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          )}

          <TextField
            variant="outlined"
            placeholder={t("search")}
            fullWidth={isSmallerThanLg}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          {(selectedGroup || tabIndex === 1) && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={
                tabIndex === 0
                  ? handleOpenInviteUserModal
                  : handleOpenAssignUserDialog
              }
              startIcon={<Add />}
            >
              {tabIndex === 0 ? t("inviteUserToGroup") : t("assignSystemRole")}
            </LoadingButton>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between">
          {hasPermissionForSystemRoles && (
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label={t("group")} />
              <Tab label={t("system")} />
            </Tabs>
          )}
          {tabIndex === 0 && (
            <Box display="flex" alignItems="center" gap="4px">
              <Switch
                checked={showInvitations}
                onChange={handleShowInvitationsToggle}
                title={t("showInvitations")}
              />
              <Typography>{t("showInvitations")}</Typography>
            </Box>
          )}
        </Box>
        <AssignUserDialog
          isOpen={isAssignUserDialogOpen}
          tabIndex={tabIndex}
          onClose={handleCloseAssignUserDialog}
          selectedGroup={selectedGroup}
          predefinedUser={predefinedUser}
        />

        <InviteUserDialog
          selectedGroup={selectedGroup}
          isOpen={isInviteUserModalOpen}
          onClose={handleCloseInviteUserModal}
        />

        {!showInvitations && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="45%">
                    <TableSortLabel
                      active={sortBy === "name"}
                      direction={sortOrder}
                      onClick={() => handleSort("name")}
                    >
                      {t("name")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="45%">
                    <TableSortLabel
                      active={sortBy === "email"}
                      direction={sortOrder}
                      onClick={() => handleSort("email")}
                    >
                      {t("email")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="5%" />
                  {(selectedGroup || tabIndex === 1) && (
                    <TableCell width="5%" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isGroupLoading ||
                isGroupRefetching ||
                isLoadingUsersByRole ||
                isRefetchingUsersByRole ? (
                  <>
                    {[...Array(filters.pageSize)].map((_, index) => (
                      <TableRow key={index}>
                        {[
                          ...Array(selectedGroup || tabIndex === 1 ? 4 : 3),
                        ].map((_, cellIndex) => (
                          <TableCell key={cellIndex}>
                            <Skeleton />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </>
                ) : (
                  paginatedUsers?.map((row) => (
                    <TableRow key={row.userId}>
                      <TableCell>
                        {`${row.firstName ?? ""} ${row.lastName ?? ""}`?.trim()}
                      </TableCell>
                      <TableCell>{row.email ?? ""}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            if (selectedGroup) {
                              handleEditClick(row.userId || row.id, {
                                claimRoles: row.claimRoles,
                                groupRole: row.role.type,
                              })
                            } else {
                              handleAssignClick(row.userId || row.id)
                            }
                          }}
                        >
                          <EditOutlinedIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      {(selectedGroup || tabIndex === 1) && (
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              handleDeleteClick(row.id || row.userId)
                            }
                          >
                            <DeleteOutlinedIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {isGroupLoading ||
                  isGroupRefetching ||
                  isLoadingUsersByRole ||
                  isRefetchingUsersByRole ? (
                    <TableCell
                      colSpan={selectedGroup || tabIndex === 1 ? 4 : 3}
                    >
                      <Skeleton />
                    </TableCell>
                  ) : (
                    <TablePagination
                      count={filteredUsers.length ?? 0}
                      page={filters.page}
                      rowsPerPage={filters.pageSize}
                      onPageChange={onPageChange}
                      onRowsPerPageChange={onPageSizeChange}
                      labelRowsPerPage={t("rowsPerPage")}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
        {showInvitations && invitations && (
          <InvitationsTable groupId={selectedGroup} invitations={invitations} />
        )}
      </Box>
      <AlertDialog
        isVisible={deleteDialogOpen}
        message={
          tabIndex === 0
            ? t("areYouSureYouWantToDeleteUser")
            : t("areYouSureYouWantToRemoveRoleFromUser")
        }
        confirmLabel={t("delete")}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        loading={isDeleting}
      />
      <UpdateUserRolesDialog
        isOpen={isUpdateRolesDialogOpen}
        onClose={handleCloseUpdateRolesDialog}
        groupId={selectedGroup as string}
        userId={selectedUserForRoles?.userId as string}
        userRoles={
          selectedUserForRoles?.userRoles as {
            claimRoles: string[]
            groupRole: string
          }
        }
      />
    </Box>
  )
}
