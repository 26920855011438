import { useEffect, useMemo } from "react"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { inviteUserToGroupAPI, getOrganizationsAPI } from "../../services"
import { useToast } from "../../contexts"
import { Controller, useForm } from "react-hook-form"
import { EMAIL_REGEX_VALIDATION } from "../../utils"

interface InviteUserModalProps {
  isOpen: boolean
  onClose: () => void
  selectedGroup: string | undefined
  selectedRole?: string
}

export const InviteUserDialog = ({
  isOpen,
  onClose,
  selectedGroup,
}: InviteUserModalProps) => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()

  const { data: organizations } = useQuery({
    queryKey: ["organizations"],
    queryFn: () => getOrganizationsAPI(),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ email: string; roleId: string; claimRoleIds: string[] }>({
    defaultValues: {
      email: "",
      roleId: "",
      claimRoleIds: [],
    },
  })

  const { mutate: inviteUser, isPending: isInviting } = useMutation({
    mutationFn: (data: IInviteUserToGroup) => inviteUserToGroupAPI(data),
    onSuccess: () => {
      toast.show(t("userInvited"), "success")
      void queryClient.refetchQueries({ queryKey: ["groupUsers"] })
      onClose()
    },
  })

  const onSubmit = (data: any) => {
    if (selectedGroup) {
      inviteUser({
        email: data.email,
        roleId: data.roleId,
        claimRoleIds: data.claimRoleIds,
        groupId: selectedGroup,
      })
    }
  }

  const selectedOrganization = useMemo(() => {
    if (!organizations || !selectedGroup) return null
    return organizations.find((org) =>
      org.groups.some((group) => group.id === selectedGroup),
    )
  }, [organizations, selectedGroup])

  const sortedGroupRoles = useMemo(() => {
    if (!selectedOrganization) return []
    return selectedOrganization.roles
      .map((role) => ({
        value: role.id,
        label: t(role.type),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [selectedOrganization, t])

  const sortedClaimRoles = useMemo(() => {
    if (!selectedOrganization) return []
    return selectedOrganization.claimRoles
      .map((claimRole) => ({
        value: claimRole.id,
        label: t(claimRole.claimRoleType.toLowerCase()),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [selectedOrganization, t])

  useEffect(() => {
    if (isOpen) {
      reset({
        email: "",
        roleId: "",
        claimRoleIds: [],
      })
    }
  }, [isOpen, reset])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("inviteUserToGroup")}</DialogTitle>
        <DialogContent>
          <Controller
            name="email"
            control={control}
            rules={{
              required: t("required"),
              pattern: {
                value: EMAIL_REGEX_VALIDATION,
                message: t("emailNotValid"),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <TextField
                fullWidth
                value={value}
                onChange={onChange}
                label={t("email")}
                error={!!errors?.email}
                helperText={errors?.email?.message}
                margin="normal"
              />
            )}
          />
          <FormControl fullWidth error={!!errors.roleId} margin="normal">
            <InputLabel>{t("groupRole")}</InputLabel>
            <Controller
              name="roleId"
              control={control}
              rules={{ required: t("required") }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <Select
                    value={value}
                    onChange={onChange}
                    label={t("groupRole")}
                    error={!!error}
                  >
                    {sortedGroupRoles.map((role) => (
                      <MenuItem key={role.value} value={role.value}>
                        {role.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors?.roleId}>
                    {error?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>

          <FormControl fullWidth error={!!errors.claimRoleIds} margin="normal">
            <InputLabel>{t("claimRoleType")}</InputLabel>
            <Controller
              name="claimRoleIds"
              control={control}
              rules={{ required: t("required") }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <Select
                    multiple
                    value={value}
                    onChange={onChange}
                    label={t("claimRoleType")}
                    error={!!error}
                    renderValue={(selected) =>
                      selected
                        .map((id) => {
                          const selectedClaimRole = sortedClaimRoles.find(
                            (role) => role.value === id,
                          )
                          return selectedClaimRole
                            ? selectedClaimRole.label
                            : ""
                        })
                        .join(", ")
                    }
                  >
                    {sortedClaimRoles.map((claimRole) => (
                      <MenuItem key={claimRole.value} value={claimRole.value}>
                        {claimRole.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors?.claimRoleIds}>
                    {error?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isInviting}
          >
            {isInviting ? <CircularProgress size={24} /> : t("invite")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default InviteUserDialog
